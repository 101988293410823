#{$theme-scope} {

  // <nav class="sn-split-horiz sn-side-nav">
  //   <section class="sn-pane shrink">
  //     <div class="sn-side-nav-title">
  //       <h2>Minnesota</h2>
  //       <h2>Solo</h2>
  //       <h1>2015</h1>
  //     </div>
  //     <div class="sn-row condense">
  //       <div class="sn-col-12">
  //         <a class="sn-link">Change Season</a>
  //       </div>
  //     </div>
  //     <div class="sn-row collapse">
  //       <div class="sn-col-12">
  //         <span class="sn-tag locked">Beta</span>
  //       </div>
  //     </div>
  //   </section>
  //   <section class="sn-pane">
  //     <ul class="sn-side-nav-menu">
  //       <li><a class="sn-menu-item active" href="#"><span class="icon ss-icon ss-usergroup"></span>Roster</a></li>
  //       <li><a class="sn-menu-item" href="#"><span class="icon ss-icon ss-calendar"></span>Schedule</a></li>
  //     </ul>
  //     <ul class="sn-side-nav-links">
  //       <li><a href="#" class="sn-link">Go to Team Page</a></li>
  //       <li><a href="#" class="sn-link">Help</a></li>
  //     </ul>
  //   </section>
  // </nav>

  // <nav class="sn-split-horiz sn-side-nav">
  //   <section class="sn-pane shrink">
  //     <div class="sn-side-nav-title">
  //       <h1>Seasons</h1>
  //       <button type="button" class="sn-close-button"></button>
  //     </div>
  //   </section>
  //   <section class="sn-pane">
  //     <ul class="sn-side-nav-menu">
  //       <h3 class="sn-side-nav-section-title">The League</h3>
  //       <li>
  //         <a href="#" class="sn-menu-item active">Season One</a>
  //         <a href="#" class="sn-menu-item">Season Two</a>
  //         <a href="#" class="sn-menu-item">Season Three</a>
  //       </li>
  //     </ul>
  //   </section>
  // </nav>



  .sn-side-nav {
    position: relative;
    z-index: $sn-z-dropdown;
    @include flex(0, 0, $sn-side-nav-width);
    padding: 0;
    background: #f2f3f4; // copied from platform side-nav
    border-right: 1px solid $sn-base-color-72;

    .sn-link { font-size: $sn-font-size-sm; }

    > .sn-pane {

      &:not(:first-child) {
        padding-top: $sn-spacing-condensed;
        border-top: 1px solid $sn-base-color-72;
      }
      &:not(:last-child) {
        padding-bottom: $sn-spacing-condensed;
      }
    }

    > * {
      background: transparent;
    }
  }

  .sn-side-nav-title {
    text-align: center;
    margin-bottom: $sn-spacing-condensed;

    h1, h2, h3 {
      font-weight: $sn-font-weight-normal;
    }

    h1, h2 {
      color: $sn-base-color--66;
      font-size: $sn-font-size-lg;
      margin-bottom: 10px;
    }

    h3 {
      font-size: $sn-font-size-sm;
    }
  }

  .sn-side-nav-section-title {
    color: $sn-base-color--22;
    font-size: $sn-font-size-xs;
    padding: 0 0 $sn-spacing-extra-condensed;
    text-transform: uppercase;
  }

  .sn-side-nav-popover {
    position: absolute;
    box-sizing: content-box;
    width: $sn-side-nav-width;
    top: 0;
    left: 0 - 1 - $sn-side-nav-width;
    bottom: 0;
    background: $sn-base-color-90;
    border-right: 1px solid $sn-base-color-72;
    transition: 300ms;

    &.showing { left: 0; }
  }

  .sn-side-nav-links {
    list-style: none;

    &:not(:first-child) { margin-top: $sn-spacing-condensed; }
  }

  .sn-side-nav-menu {
    font-size: $sn-font-size-md;
    list-style: none;
    padding: 0;

    &:not(:first-child) { margin-top: $sn-spacing-condensed; }

    .sn-menu-item {
      position: relative;
      display: block;
      text-decoration: none;
      margin: 0 (-$sn-spacing);
      padding: .5714em 1.7143em; // copied from platform side-nav
      @include transition(background, $sn-animation-speed, ease);

      &:hover { background: rgba(0,0,0,0.025); }

      &.active {
        box-shadow: inset 4px 0 0 0 $sn-accent-color;
        background: rgba(35,40,43,.06);
      }

      .icon {
        position: absolute;
        left: 16px;
        color: $sn-base-color-36;
        font-size: 18px;
        line-height: 1;
        margin-right: 8px;
      }
      svg.icon {
        fill: $sn-base-color-36;
        height: 1em;
        width: 1em;
        vertical-align: middle;
      }
    }
  }

}
