#{$theme-scope} {

  .sn-tabs {
    list-style: none;
    padding: 0 $sn-spacing;
    margin: 16px 0-$sn-spacing 0;
    border-bottom: 1px solid $sn-base-color-72;

    > .sn-tab {
      position: relative;
      cursor: pointer;
      display: inline-block;
      padding: 0 0 8px;
      margin: 0 8px;
      text-decoration: none;

      &:first-child { margin-left: 0; }
      &:last-child { margin-right: 0; }

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 0;
        background: transparent;
        @include transition(all, $sn-animation-speed, ease);
      }

      &.active:after {
        background: $sn-accent-color;
        height: 4px;
      }

      > a {
        text-decoration: inherit;
        color: inherit;
      }
    }
  }

}
