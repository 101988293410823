#{$theme-scope} {

  .sn-modal-overlay {
    @include sn-fx-fade;
    @include flex-box(column);
    position: fixed;
    z-index: $sn-z-modal;
    background: rgba(0, 0, 0, 0.4);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    -webkit-animation: fadein $sn-animation-speed;
    animation: fadein $sn-animation-speed;
  }

  .sn-modal {
    @include flex-box(column);
    background: #fff;
    max-width: 75%;
    max-height: 75%;
    margin: 0 auto;
    border: 1px solid $sn-base-color-72;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.1);

    &.extra-small { width: 300px; }
    &.small { width: 400px; }
    &.medium { width: 500px; }
    &.medium-large { width: 600px; }
    &.large { width: 750px; }

    // Columns don't work with auto height :(
    &.multi-column, &.multi-column-add { height: 100%; }

    .sn-modal-header {
      @extend .sn-page-header;
      position: relative;
      padding: ($sn-spacing - 4) $sn-spacing;

      .sn-modal-close {
        font-size: $sn-input-sm;
        display: block;
        position: absolute;
        top: 24px;
        right: 24px;
        margin: 0;
      }

      .sn-modal-close + h1 { padding-right: 40px; }
    }

    .sn-modal-content { @include flex(1,1); }

    .sn-modal-footer {
      z-index: 1; // fixes border issue (probably rounding related)
      text-align: right;
      padding: 12px 24px;
      line-height: $sn-input-lg; // to match button height

      .sn-notification:before { line-height: $sn-input-lg; }
    }
  }
}

// THESE CANNOT BE NESTED

// IE 10/11 cannot figure out the height unless specifically set
// Apps using this should have JS set the height based on content.
// This is just a fallback to prent stuff from totally breaking.
.ie10 .sn-modal,
.ie11 .sn-modal { height: 50%; }

body.ngdialog-open { overflow: hidden; }

@at-root {

  @-webkit-keyframes ng-dialog-fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }

  @keyframes ng-dialog-fadeout {
    0% { opacity: 1;}
    100% {
      opacity: 0;
      display: none;
    }
  }

  @-webkit-keyframes ng-dialog-fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes ng-dialog-fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
}

.sn-modal-overlay {
  -webkit-backface-visibility: hidden;
  -webkit-animation: ng-dialog-fadein $sn-animation-speed;
  animation: ng-dialog-fadein $sn-animation-speed;
}

.ngdialog.ngdialog-closing .sn-modal-overlay,
.sn-modal-overlay.ng-leave {
  -webkit-backface-visibility: hidden;
  -webkit-animation: ng-dialog-fadeout $sn-animation-speed forwards;
  animation: ng-dialog-fadeout $sn-animation-speed forwards;
}
