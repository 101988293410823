#{$theme-scope} {

  $radio-size: 12px;
  $sn-radio-panel-icon-size: 32px;

  %radio-button-input {
    position: absolute;
    left: -9999in;
  }

  %radio-button-label {
    // We need to get these synced up across
    // components and put in _variables.scss
    display: inline-block;
    cursor: pointer;
    vertical-align: middle;

    &:before {
      content: ' ';
      font-size: 10px;
      text-align: center;
      vertical-align: middle;
      display: inline-block;
      margin-top: -2px;
      margin-right: 4px;
      width: $radio-size;
      height: $radio-size;
      background: #fff;
      border: 1px solid $sn-base-color-54; // changes based on parent background
      border-radius: 100%;
      box-shadow: inset 0 0 0 2px #fff;
    }

    &:hover:before {
      border-color: $sn-base-color-18; // changes based on parent background
    }

    &:active:before {
      color: $sn-accent-color; // changes based on parent background
      border-color: $sn-accent-color; // changes based on parent background
    }

    &:empty:before { margin-right: 0; }
  }

  %radio-button-label-checked {
    background: $sn-accent-color;
  }

  %radio-button-label-focus {
    color: $sn-accent-color; // changes based on parent background
    border-color: $sn-accent-color; // changes based on parent background
  }

  input.sn-radio-button {
    @extend %radio-button-input;

    /* Default style */

    & + label.sn-radio-button { @extend %radio-button-label; }
    &:checked + label.sn-radio-button:before { @extend %radio-button-label-checked; }
    &:focus + label.sn-radio-button:before { @extend %radio-button-label-focus; }

    &:checked + label.sn-radio-panel {
      z-index: 2;
      border-color: $sn-accent-color;
      background: $sn-accent-color-95;

      &:before { @extend %radio-button-label-checked; }
    }

    &:focus + label.sn-radio-panel {
      z-index: 1;
      border-color: $sn-accent-color;

      &:before { @extend %radio-button-label-focus; }
    }
  }


  /* Panel style (widh border) */

  label.sn-radio-panel {
    @extend %radio-button-label;
    @include flex-box(row);
    max-width: 100%;
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-color: $sn-base-color-72;
    padding-top: $sn-spacing-extra-condensed / 2;
    padding-bottom: $sn-spacing-extra-condensed / 2;
    padding-right: $sn-spacing-extra-condensed;
    padding-left: $sn-spacing-extra-condensed * 2 + $radio-size;

    &:not(.first) { margin-top: -1px; }

    &:before {
      position: absolute;
      top: 50%;
      margin-top: -$radio-size / 2;
      left: $sn-spacing-extra-condensed;
    }

    .sn-radio-panel-icon {
      @include flex(0, 0, $sn-radio-panel-icon-size + $sn-spacing-extra-condensed);
      fill: $sn-base-color;
      height: $sn-radio-panel-icon-size;
      padding-right: $sn-spacing-extra-condensed;
    }

    .sn-radio-panel-text {
      @include flex(1, 1, 100%);
    }
  }

}
