#{$theme-scope} {

  .sn-list-grid {
    @include no-select;

    & > h1 {
      color: $sn-base-color--66;
      font-size: $sn-font-size-lg;
      font-weight: $sn-font-weight-normal;
      text-align: left;
      padding-bottom: 10px;
      border-bottom: 1px solid $sn-base-color-72;
      margin-bottom: 10px;
    }
  }

  .sn-list-grid-items {
    position: relative;
    list-style: none;
    margin: 12px 0;
  }

  .sn-list-grid-item {
    position: relative;
    color: $sn-base-color--44;
    padding: 6px;
    margin: 6px 0;
    border: 1px solid $sn-base-color-72;
    border-radius: 2px;
    background-color: #FFF;

    &.draggable {
      cursor: pointer;
      padding-left: 16px;

      // drag handle
      &:before {
        font: normal normal normal $sn-font-size-md/1 FontAwesome;
        content: $fa-var-ellipsis-v;
        position: absolute;
        top: 50%;
        left: 6px;
        line-height: 24px;
        margin-top: -12px;
        color: $sn-base-color-54;
      }

      &.align-top:before {
        top: 6px;
        margin-top: 0;
      }

      &:hover {
        border-color: $sn-base-color-18;
      }

      &.dragging {
        border-color: $sn-base-color-18;
        box-shadow: 0 0 8px rgba(0,0,0,0.2);
      }

      &.selected,
      &.selected:hover {
        color: #FFF;
        border-color: $sn-accent-color;
        background-color: $sn-accent-color;
      }

      &.disabled {
        color: $sn-base-color-72;
        border-color: $sn-base-color-72;
        background-color: #FFF;
      }
    }
  }

}
