// NOTE: This component uses the slider and input-group styles.

.sn-pagination {
  @include no-select;
  cursor: default;
  font-size: $sn-font-size-sm;

  // nested/scoped for specificity
  input.sn-text-field.sn-pagination__field {
    padding: 0 2px 1px;
    text-align: center;
    vertical-align: baseline;
  }
}

.sn-pagination__options {
  line-height: $sn-input-sm;
  font-size: $sn-font-size-sm;
  white-space: nowrap;
  line-height: $sn-input-md;
}

.sn-pagination__separator {
  padding: 0 0.4em;

  &:before {
    color: $sn-base-color-36;
    content: "\25CF";
  }
}
