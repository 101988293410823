#{$theme-scope} {

  $editor-height: 350px;
  $img-height: 300px;

  .sn-img-editor {
    width: 100%;
    height: $editor-height;
    position: relative;
    display: table;

    .sn-img-editor-content,
    .sn-empty-state {
      display: table-cell;
      table-layout: fixed;
      vertical-align: middle;
    }

    .sn-img-editor-preview {

      > img {
        display: block;
        margin: auto;
        height: auto;
        width: auto;
        max-height: 100%;
        max-width: 100%;
      }

      .sn-avatar {
        @extend .sn-avatar;
        margin: auto;
        width: $img-height;
        height: $img-height;
      }
    }

    .sn-img-editor-crop { height: $img-height; }

    img-crop {
      width: 100%;
      height: 100%;
      display: block;
      position: relative;
      overflow: hidden;

      canvas {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;

        // Disable Outline
        outline:  none;
        -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); /* mobile webkit */
      }
    }
  }

}

