#{$theme-scope} {
    // <section class="empty-state">
    //   <i class="empty-state-icon ss-icon ss-usergroup"></i>
    //   <h1 class="empty-state-title">No Players</h1>
    //   <p class="empty-state-message">
    //     You can add players manually or import a file full of them.
    //     If you're importing, <a href="#">download our template.</a>
    //   </p>
    //   <button class="sn-button minimal"><i class="fa fa-plus"></i> Add Players</button>
    //   <button class="sn-button minimal"><i class="fa fa-upload"></i> Import Player</button>
    // </section>

  $icon-size: 48px;
  $svg-icon-size: 64px; // these icons have padding built in

  .sn-empty-state {
    text-align: center;

    .sn-empty-state-icon {
      color: $sn-base-color;
      font-size: $icon-size;
      line-height: 1;
      margin-bottom: $sn-spacing-condensed;
    }

    svg.sn-empty-state-icon {
      fill: $sn-base-color;
      width: $svg-icon-size;
      height: $svg-icon-size;
      line-height: 1;
      margin-bottom: 0;
    }

    .sn-empty-state-title {
      font-size: 24px;
      font-weight: $sn-font-weight-light;
      margin: $sn-spacing-condensed 0;
    }

    .sn-empty-state-message {
      color: $sn-base-color;
      margin-top: $sn-spacing-extra-condensed - $sn-spacing-condensed;
      margin: $sn-spacing-condensed auto $sn-spacing auto;
      max-width: 30em;
    }

    &.spaced {
      margin-top: $icon-size + $sn-spacing;
    }

    &.dashed {
      border-radius: 4px;
      border: 1px dashed $sn-base-color-54;
      padding: $sn-spacing;
    }

    &.overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: $sn-z-inline * 2;
      background-color: rgba(255, 255, 255, .75);
      padding: $sn-spacing;
    }
  }
}
