#{$theme-scope} {
  .sn-dropdown-menu {
    @include sn-dropdown-menu;
    margin: 1px 0;
    position: absolute;

    // adjust for use with minimal buttons because
    &.minimal {
    	margin: 1px 0 1px -8px;
    }

    &.tether-out-of-bounds { display: none !important; }

    > * {
      @include sn-dropdown-menu-item;
      
      &:hover { @include sn-dropdown-menu-item-highlighted; }
    }
  }
}
