#{$theme-scope} {

  // EXAMPLE INPUT

  // <div class="sn-field">
  //   <textarea class="sn-text-area"></textarea>
  // </div>

  .sn-text-area {
    @include sn-typeable-multi-line;
    resize: both;

    &.resize-x { resize: horizontal; }
    &.resize-y { resize: vertical; }
    &.resize-none { resize: none; }
  }

  // validation styles when inside sn-field

  .sn-field {
    &.error .sn-text-area {
      border-color: $sn-error-color;
      &:hover, &:focus { border-color: $sn-error-color--22; }
    }

    &.warning .sn-text-area {
      border-color: $sn-warning-color;
      &:hover, &:focus { border-color: $sn-warning-color--22; }
    }

    &.success .sn-text-area {
      border-color: $sn-success-color;
      &:hover, &:focus { border-color: $sn-success-color--22; }
    }
  }

}
