@import "../../assets/shared-variables";

#{$theme-scope} {

  @keyframes panel-from-right {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes panel-from-center {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes panel-from-bottom {
    0% {
      opacity: 0;
      transform: scale(0);
      transform-origin: bottom;
    }
    100% {
      opacity: 1;
      transform: scale(1);
      transform-origin: bottom;
    }
  }

  .sn-panel-medium,
  .sn-panel-large {
    border-style: solid;
    border-color: $sn-base-color-72;
    background: #fff;
    z-index: $sn-z-dropdown;

    // <div class="sn-pane sn-panel-header shrink">
    //   <div class="sn-avatar-large">
    //     <img src="profilepic.png" />
    //     <div class="edit-actions">
    //       <button class="sn-button minimal"><i class="fa fa-search-plus"></i></button>
    //       <button class="sn-button minimal"><i class="fa fa-camera"></i></button>
    //     </div>
    //   </div>
    //   <div class="sn-panel-header-title">
    //     <h1 class="title">Buster Bluth</h1>
    //     <div class="subtitle">Owner, Manager</div>
    //     <div class="subtitle">Age 35</div>
    //   </div>
    // </div>
  }

  .sn-panel-info {
    background: $sn-base-color-97;
    border-bottom: 1px solid $sn-base-color-72;
    color: $sn-base-color;
    padding: ($sn-spacing - 4) $sn-spacing;
  }

  .sn-panel-header {
    padding: ($sn-spacing - 4) $sn-spacing;
    border-bottom: 1px solid $sn-base-color-72;

    .sn-panel-header-title {
      margin-bottom: $sn-spacing-extra-condensed / 2;

      .title { font-weight: $sn-font-weight-normal; }
      h1.title { font-size: 24px; }
      h2.title { font-size: 20px; }
      h3.title { font-size: 16px; }
    }

    .sn-tabs {
      border-bottom: none;
    }
  }

  .sn-panel-medium {
    border-width: 0 0 0 1px;
    width: 66.6666666666%;
    right: 0;
    left: auto;
    // animation: panel-from-right $sn-animation-speed ease-in-out;
  }

  .sn-panel-large {
    border-width: 1px;
    top: $sn-spacing;
    left: $sn-spacing;
    right: $sn-spacing;
    bottom: 0;
    // animation: panel-from-bottom $sn-animation-speed ease-in-out;

    .sn-save-bar {
      box-sizing: content-box;
      background: #FFF;
      padding-left: $sn-spacing + 1;
      padding-right: $sn-spacing + 1;
    }
  }

  .sn-panel-full-screen {
    position: fixed; // full panel covers the ngin bar
    background: #FFF;
    z-index: $sn-z-modal - 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // animation: panel-from-center $sn-animation-speed ease-in-out;
    &.with-ngin-bar {
      top: $ngin-bar-height;
      z-index: $sn-z-modal - 3;
    }
  }
}
