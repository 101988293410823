#{$theme-scope} {

  // Adjusts the space to equal input height when positioned right or left
  // Calc: (24 - (12 * 1.3) - 12) / 2
  $tool-tip-spacing: ($sn-input-md - ($sn-font-size-sm * $sn-line-height) - $sn-font-size-sm) / 2;


  .sn-tool-tip {
    position: absolute;
    z-index: $sn-z-dropdown;
    font-size: $sn-font-size-sm;
    font-weight: $sn-font-weight-normal;
    max-width: 200px;
    padding: .5em .66em;
    color: #fff;
    text-align: left;
    border-radius: 4px;
    background: $sn-base-color-18;
    box-shadow: $sn-shadow-dropdown;
    word-break: break-word;

    &.inline {
      position: relative;
      display: inline-block;
      vertical-align: middle;

      &.above { margin-bottom: .7em; }
      &.below { margin-top: .7em; }
      &.right { margin: $tool-tip-spacing 0 $tool-tip-spacing .7em; }
      &.left { margin-right: $tool-tip-spacing .7em $tool-tip-spacing 0; }
    }

    &.tether-out-of-bounds { display: none !important; }

    &:before {
      position: absolute;
      display: block;
      height: 0;
      border-style: solid;
      border-color: $sn-base-color-18;
      border-width: .5em;
    }

    &.small {
      font-size: $sn-font-size-xs;
      &:before { font-size: 8px; } // hack to adjust carret/padding with minimal effort
    }

    &.success {
      background: $sn-success-color;
      &:before { border-color: $sn-success-color; }
    }

    &.warning {
      background: $sn-warning-color;
      &:before { border-color: $sn-warning-color; }
    }

    &.error {
      background: $sn-error-color;
      &:before { border-color: $sn-error-color; }
    }

    &.hide-arrow-when-pinned.tether-pinned:before {
      display: none;
    }

    &.above:before,
    &.below.tether-element-attached-bottom:before {
      content: '';
      top: 100%;
      left: 50%;
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom-width: 0;
      border-top-width: .5em;
      margin-left: -.5em;
    }

    &.below:before,
    &.above.tether-element-attached-top:before {
      content: '';
      bottom: 100%;
      left: 50%;
      border-left-color: transparent;
      border-right-color: transparent;
      border-top-width: 0;
      border-bottom-width: .5em;
      margin-left: -.5em;
    }

    &.left:before,
    &.right.tether-element-attached-right:before {
      content: '';
      left: 100%;
      top: 50%;
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-right-width: 0;
      border-left-width: .5em;
      margin-top: -.5em;
    }

    &.right:before,
    &.left.tether-element-attached-left:before {
      content: '';
      right: 100%;
      top: 50%;
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-left-width: 0;
      border-right-width: .5em;
      margin-top: -.5em;
    }

    &.tether-element-attached-right { margin-left: -8px; }
    &.tether-element-attached-left { margin-left: 8px; }
    &.tether-element-attached-bottom { margin-top: -8px; }
    &.tether-element-attached-top { margin-top: 8px; }
  }

}
