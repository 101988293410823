.ss-cursor:before,
.ss-cursor.right:after{content:''}

.ss-crosshair:before,
.ss-crosshair.right:after{content:'⌖'}

.ss-search:before,
.ss-search.right:after{content:'🔎'}

.ss-zoomin:before,
.ss-zoomin.right:after{content:''}

.ss-zoomout:before,
.ss-zoomout.right:after{content:''}

.ss-view:before,
.ss-view.right:after{content:'👁'}

.ss-binoculars:before,
.ss-binoculars.right:after{content:''}

.ss-incognito:before,
.ss-incognito.right:after{content:''}

.ss-radar:before,
.ss-radar.right:after{content:''}

.ss-attach:before,
.ss-attach.right:after{content:'📎'}

.ss-link:before,
.ss-link.right:after{content:'🔗'}

.ss-write:before,
.ss-write.right:after{content:'✎'}

.ss-compose:before,
.ss-compose.right:after{content:'📝'}

.ss-lock:before,
.ss-lock.right:after{content:'🔒'}

.ss-unlock:before,
.ss-unlock.right:after{content:'🔓'}

.ss-combinationlock:before,
.ss-combinationlock.right:after{content:''}

.ss-key:before,
.ss-key.right:after{content:'🔑'}

.ss-keyhole:before,
.ss-keyhole.right:after{content:''}

.ss-vip:before,
.ss-vip.right:after{content:''}

.ss-trash:before,
.ss-trash.right:after{content:'🗑'}

.ss-target:before,
.ss-target.right:after{content:'◎'}

.ss-bullseye:before,
.ss-bullseye.right:after{content:'🎯'}

.ss-lightning:before,
.ss-lightning.right:after{content:'☇'}

.ss-radioactive:before,
.ss-radioactive.right:after{content:'☢'}

.ss-skull:before,
.ss-skull.right:after{content:'💀'}

.ss-skullandcrossbones:before,
.ss-skullandcrossbones.right:after{content:'☠'}

.ss-tag:before,
.ss-tag.right:after{content:'🏷'}

.ss-tags:before,
.ss-tags.right:after{content:''}

.ss-pricetag:before,
.ss-pricetag.right:after{content:''}

.ss-flag:before,
.ss-flag.right:after{content:'⚑'}

.ss-like:before,
.ss-like.right:after{content:'👍'}

.ss-dislike:before,
.ss-dislike.right:after{content:'👎'}

.ss-heart:before,
.ss-heart.right:after{content:'♥'}

.ss-star:before,
.ss-star.right:after{content:'⋆'}

.ss-archive:before,
.ss-archive.right:after{content:'🗃'}

.ss-badge:before,
.ss-badge.right:after{content:'📛'}

.ss-trophy:before,
.ss-trophy.right:after{content:'🏆'}

.ss-medal:before,
.ss-medal.right:after{content:'🎖'}

.ss-crown:before,
.ss-crown.right:after{content:'👑'}

.ss-layers:before,
.ss-layers.right:after{content:''}

.ss-layergroup:before,
.ss-layergroup.right:after{content:''}

.ss-cut:before,
.ss-cut.right:after{content:'✂'}

.ss-magicwand:before,
.ss-magicwand.right:after{content:''}

.ss-clipboard:before,
.ss-clipboard.right:after{content:'📋'}

.ss-ruler:before,
.ss-ruler.right:after{content:'📏'}

.ss-brush:before,
.ss-brush.right:after{content:''}

.ss-paintroller:before,
.ss-paintroller.right:after{content:''}

.ss-rubberstamp:before,
.ss-rubberstamp.right:after{content:''}

.ss-palette:before,
.ss-palette.right:after{content:'🎨'}

.ss-phone:before,
.ss-phone.right:after{content:'📞'}

.ss-voicemail:before,
.ss-voicemail.right:after{content:'⌕'}

.ss-megaphone:before,
.ss-megaphone.right:after{content:'📢'}

.ss-at:before,
.ss-at.right:after{content:'@'}

.ss-reply:before,
.ss-reply.right:after{content:'↩'}

.ss-replyall:before,
.ss-replyall.right:after{content:''}

.ss-mailbox:before,
.ss-mailbox.right:after{content:'📫'}

.ss-send:before,
.ss-send.right:after{content:'🖅'}

.ss-mail:before,
.ss-mail.right:after{content:'✉'}

.ss-inbox:before,
.ss-inbox.right:after{content:'📥'}

.ss-stamp:before,
.ss-stamp.right:after{content:''}

.ss-chat:before,
.ss-chat.right:after{content:'💬'}

.ss-chats:before,
.ss-chats.right:after{content:'🗪'}

.ss-ellipsischat:before,
.ss-ellipsischat.right:after{content:''}

.ss-ellipsis:before,
.ss-ellipsis.right:after{content:'…'}

.ss-smile:before,
.ss-smile.right:after{content:'☻'}

.ss-user:before,
.ss-user.right:after{content:'👤'}

.ss-femaleuser:before,
.ss-femaleuser.right:after{content:'👧'}

.ss-users:before,
.ss-users.right:after{content:'👥'}

.ss-baby:before,
.ss-baby.right:after{content:'👶'}

.ss-alien:before,
.ss-alien.right:after{content:'👽'}

.ss-ghost:before,
.ss-ghost.right:after{content:'👻'}

.ss-footsteps:before,
.ss-footsteps.right:after{content:'👣'}

.ss-usergroup:before,
.ss-usergroup.right:after{content:''}

.ss-contacts:before,
.ss-contacts.right:after{content:'📇'}

.ss-userprofile:before,
.ss-userprofile.right:after{content:''}

.ss-cart:before,
.ss-cart.right:after{content:''}

.ss-shoppingbag:before,
.ss-shoppingbag.right:after{content:'🛍'}

.ss-gift:before,
.ss-gift.right:after{content:'🎁'}

.ss-store:before,
.ss-store.right:after{content:'🏪'}

.ss-barcode:before,
.ss-barcode.right:after{content:''}

.ss-creditcard:before,
.ss-creditcard.right:after{content:'💳'}

.ss-creditcards:before,
.ss-creditcards.right:after{content:''}

.ss-banknote:before,
.ss-banknote.right:after{content:'💵'}

.ss-currency:before,
.ss-currency.right:after{content:'💱'}

.ss-piggybank:before,
.ss-piggybank.right:after{content:''}

.ss-calculator:before,
.ss-calculator.right:after{content:'🖩'}

.ss-tally:before,
.ss-tally.right:after{content:''}

.ss-bank:before,
.ss-bank.right:after{content:'🏦'}

.ss-scales:before,
.ss-scales.right:after{content:'⚖'}

.ss-gavel:before,
.ss-gavel.right:after{content:''}

.ss-meeting:before,
.ss-meeting.right:after{content:''}

.ss-presentation:before,
.ss-presentation.right:after{content:''}

.ss-piechart:before,
.ss-piechart.right:after{content:''}

.ss-barchart:before,
.ss-barchart.right:after{content:'📊'}

.ss-activity:before,
.ss-activity.right:after{content:''}

.ss-flowchart:before,
.ss-flowchart.right:after{content:''}

.ss-stock:before,
.ss-stock.right:after{content:'🗠'}

.ss-box:before,
.ss-box.right:after{content:'📦'}

.ss-crate:before,
.ss-crate.right:after{content:''}

.ss-handtruck:before,
.ss-handtruck.right:after{content:''}

.ss-home:before,
.ss-home.right:after{content:'⌂'}

.ss-fence:before,
.ss-fence.right:after{content:''}

.ss-buildings:before,
.ss-buildings.right:after{content:'🏢'}

.ss-hotel:before,
.ss-hotel.right:after{content:'🏨'}

.ss-warehouse:before,
.ss-warehouse.right:after{content:''}

.ss-castle:before,
.ss-castle.right:after{content:'🏰'}

.ss-globe:before,
.ss-globe.right:after{content:'🌐'}

.ss-earth:before,
.ss-earth.right:after{content:'🌎'}

.ss-navigate:before,
.ss-navigate.right:after{content:''}

.ss-compassnavigate:before,
.ss-compassnavigate.right:after{content:''}

.ss-compass:before,
.ss-compass.right:after{content:''}

.ss-signpost:before,
.ss-signpost.right:after{content:''}

.ss-map:before,
.ss-map.right:after{content:''}

.ss-location:before,
.ss-location.right:after{content:''}

.ss-pin:before,
.ss-pin.right:after{content:'📍'}

.ss-pushpin:before,
.ss-pushpin.right:after{content:'📌'}

.ss-maplocation:before,
.ss-maplocation.right:after{content:''}

.ss-floppydisk:before,
.ss-floppydisk.right:after{content:'💾'}

.ss-puzzle:before,
.ss-puzzle.right:after{content:''}

.ss-install:before,
.ss-install.right:after{content:''}

.ss-window:before,
.ss-window.right:after{content:'🗔'}

.ss-windows:before,
.ss-windows.right:after{content:'🗗'}

.ss-commandline:before,
.ss-commandline.right:after{content:''}

.ss-bug:before,
.ss-bug.right:after{content:'🐛'}

.ss-database:before,
.ss-database.right:after{content:''}

.ss-music:before,
.ss-music.right:after{content:'♫'}

.ss-eighthnote:before,
.ss-eighthnote.right:after{content:'♪'}

.ss-guitar:before,
.ss-guitar.right:after{content:'🎸'}

.ss-piano:before,
.ss-piano.right:after{content:'🎹'}

.ss-pianokeys:before,
.ss-pianokeys.right:after{content:''}

.ss-trumpet:before,
.ss-trumpet.right:after{content:'🎺'}

.ss-mic:before,
.ss-mic.right:after{content:'🎤'}

.ss-studiomic:before,
.ss-studiomic.right:after{content:'🎙'}

.ss-headphones:before,
.ss-headphones.right:after{content:'🎧'}

.ss-mutevolume:before,
.ss-mutevolume.right:after{content:''}

.ss-volume:before,
.ss-volume.right:after{content:'🔈'}

.ss-lowvolume:before,
.ss-lowvolume.right:after{content:'🔉'}

.ss-highvolume:before,
.ss-highvolume.right:after{content:'🔊'}

.ss-ipod:before,
.ss-ipod.right:after{content:''}

.ss-stereo:before,
.ss-stereo.right:after{content:''}

.ss-speaker:before,
.ss-speaker.right:after{content:''}

.ss-vinylrecord:before,
.ss-vinylrecord.right:after{content:''}

.ss-disc:before,
.ss-disc.right:after{content:'💿'}

.ss-playlist:before,
.ss-playlist.right:after{content:''}

.ss-airplay:before,
.ss-airplay.right:after{content:''}

.ss-camera:before,
.ss-camera.right:after{content:'📷'}

.ss-picture:before,
.ss-picture.right:after{content:'🌄'}

.ss-pictures:before,
.ss-pictures.right:after{content:''}

.ss-video:before,
.ss-video.right:after{content:'📹'}

.ss-film:before,
.ss-film.right:after{content:'🎞'}

.ss-filmroll:before,
.ss-filmroll.right:after{content:''}

.ss-widescreenfilm:before,
.ss-widescreenfilm.right:after{content:''}

.ss-clapboard:before,
.ss-clapboard.right:after{content:'🎬'}

.ss-tv:before,
.ss-tv.right:after{content:'📺'}

.ss-flatscreen:before,
.ss-flatscreen.right:after{content:''}

.ss-videogame:before,
.ss-videogame.right:after{content:'🎮'}

.ss-repeat:before,
.ss-repeat.right:after{content:'🔁'}

.ss-replay:before,
.ss-replay.right:after{content:'↺'}

.ss-shuffle:before,
.ss-shuffle.right:after{content:'🔀'}

.ss-filecabinet:before,
.ss-filecabinet.right:after{content:'🗄'}

.ss-storagebox:before,
.ss-storagebox.right:after{content:''}

.ss-index:before,
.ss-index.right:after{content:'🗂'}

.ss-page:before,
.ss-page.right:after{content:'📃'}

.ss-book:before,
.ss-book.right:after{content:'📕'}

.ss-openbook:before,
.ss-openbook.right:after{content:'📖'}

.ss-notebook:before,
.ss-notebook.right:after{content:'📓'}

.ss-spiralbound:before,
.ss-spiralbound.right:after{content:'🗒'}

.ss-newspaper:before,
.ss-newspaper.right:after{content:'📰'}

.ss-notepad:before,
.ss-notepad.right:after{content:''}

.ss-cards:before,
.ss-cards.right:after{content:''}

.ss-notice:before,
.ss-notice.right:after{content:''}

.ss-grid:before,
.ss-grid.right:after{content:''}

.ss-rows:before,
.ss-rows.right:after{content:''}

.ss-menu:before,
.ss-menu.right:after{content:''}

.ss-filter:before,
.ss-filter.right:after{content:''}

.ss-ascending:before,
.ss-ascending.right:after{content:''}

.ss-descending:before,
.ss-descending.right:after{content:''}

.ss-desktop:before,
.ss-desktop.right:after{content:'💻'}

.ss-laptop:before,
.ss-laptop.right:after{content:''}

.ss-tablet:before,
.ss-tablet.right:after{content:''}

.ss-smartphone:before,
.ss-smartphone.right:after{content:''}

.ss-cell:before,
.ss-cell.right:after{content:'📱'}

.ss-battery:before,
.ss-battery.right:after{content:'🔋'}

.ss-door:before,
.ss-door.right:after{content:'🚪'}

.ss-washer:before,
.ss-washer.right:after{content:''}

.ss-birdhouse:before,
.ss-birdhouse.right:after{content:''}

.ss-doghouse:before,
.ss-doghouse.right:after{content:''}

.ss-lightbulb:before,
.ss-lightbulb.right:after{content:'💡'}

.ss-lamp:before,
.ss-lamp.right:after{content:''}

.ss-spraycan:before,
.ss-spraycan.right:after{content:''}

.ss-spraybottle:before,
.ss-spraybottle.right:after{content:''}

.ss-chair:before,
.ss-chair.right:after{content:''}

.ss-frame:before,
.ss-frame.right:after{content:'🖼'}

.ss-flowerframe:before,
.ss-flowerframe.right:after{content:''}

.ss-atom:before,
.ss-atom.right:after{content:'⚛'}

.ss-telescope:before,
.ss-telescope.right:after{content:'🔭'}

.ss-balloon:before,
.ss-balloon.right:after{content:'🎈'}

.ss-fuzzydice:before,
.ss-fuzzydice.right:after{content:''}

.ss-magiclamp:before,
.ss-magiclamp.right:after{content:''}

.ss-slingshot:before,
.ss-slingshot.right:after{content:''}

.ss-bomb:before,
.ss-bomb.right:after{content:'💣'}

.ss-gun:before,
.ss-gun.right:after{content:'🔫'}

.ss-sword:before,
.ss-sword.right:after{content:''}

.ss-swords:before,
.ss-swords.right:after{content:'⚔'}

.ss-bowandarrow:before,
.ss-bowandarrow.right:after{content:''}

.ss-targetdummy:before,
.ss-targetdummy.right:after{content:''}

.ss-downloadcloud:before,
.ss-downloadcloud.right:after{content:''}

.ss-download:before,
.ss-download.right:after{content:''}

.ss-downloadbox:before,
.ss-downloadbox.right:after{content:''}

.ss-uploadcloud:before,
.ss-uploadcloud.right:after{content:''}

.ss-upload:before,
.ss-upload.right:after{content:''}

.ss-merge:before,
.ss-merge.right:after{content:''}

.ss-fork:before,
.ss-fork.right:after{content:''}

.ss-joinpath:before,
.ss-joinpath.right:after{content:''}

.ss-splitpath:before,
.ss-splitpath.right:after{content:''}

.ss-forkpath:before,
.ss-forkpath.right:after{content:''}

.ss-refresh:before,
.ss-refresh.right:after{content:'↻'}

.ss-sync:before,
.ss-sync.right:after{content:'🗘'}

.ss-wifi:before,
.ss-wifi.right:after{content:''}

.ss-connection:before,
.ss-connection.right:after{content:''}

.ss-file:before,
.ss-file.right:after{content:'📄'}

.ss-files:before,
.ss-files.right:after{content:''}

.ss-folder:before,
.ss-folder.right:after{content:'📁'}

.ss-anchor:before,
.ss-anchor.right:after{content:'⚓'}

.ss-print:before,
.ss-print.right:after{content:'⎙'}

.ss-list:before,
.ss-list.right:after{content:''}

.ss-starlist:before,
.ss-starlist.right:after{content:''}

.ss-layout:before,
.ss-layout.right:after{content:''}

.ss-twoup:before,
.ss-twoup.right:after{content:''}

.ss-threeup:before,
.ss-threeup.right:after{content:''}

.ss-fourup:before,
.ss-fourup.right:after{content:''}

.ss-action:before,
.ss-action.right:after{content:''}

.ss-expand:before,
.ss-expand.right:after{content:'⤢'}

.ss-contract:before,
.ss-contract.right:after{content:''}

.ss-lifepreserver:before,
.ss-lifepreserver.right:after{content:''}

.ss-help:before,
.ss-help.right:after{content:'❓'}

.ss-info:before,
.ss-info.right:after{content:'ℹ'}

.ss-alert:before,
.ss-alert.right:after{content:'⚠'}

.ss-plus:before,
.ss-plus.right:after{content:'+'}

.ss-hyphen:before,
.ss-hyphen.right:after{content:'-'}

.ss-check:before,
.ss-check.right:after{content:'✓'}

.ss-fish:before,
.ss-fish.right:after{content:'🐟'}

.ss-whale:before,
.ss-whale.right:after{content:'🐋'}

.ss-bird:before,
.ss-bird.right:after{content:'🐦'}

.ss-pawprint:before,
.ss-pawprint.right:after{content:''}

.ss-bone:before,
.ss-bone.right:after{content:''}

.ss-tree:before,
.ss-tree.right:after{content:'🌲'}

.ss-flower:before,
.ss-flower.right:after{content:'⚘'}

.ss-gem:before,
.ss-gem.right:after{content:'💎'}

.ss-mountains:before,
.ss-mountains.right:after{content:'⛰'}

.ss-settings:before,
.ss-settings.right:after{content:'⚙'}

.ss-dashboard:before,
.ss-dashboard.right:after{content:''}

.ss-dial:before,
.ss-dial.right:after{content:''}

.ss-notifications:before,
.ss-notifications.right:after{content:'🔔'}

.ss-toggles:before,
.ss-toggles.right:after{content:'🎚'}

.ss-switch:before,
.ss-switch.right:after{content:''}

.ss-brightness:before,
.ss-brightness.right:after{content:'🔆'}

.ss-contrast:before,
.ss-contrast.right:after{content:'◐'}

.ss-flash:before,
.ss-flash.right:after{content:'⌁'}

.ss-magnet:before,
.ss-magnet.right:after{content:''}

.ss-level:before,
.ss-level.right:after{content:''}

.ss-toolbox:before,
.ss-toolbox.right:after{content:''}

.ss-hammer:before,
.ss-hammer.right:after{content:'🔨'}

.ss-wrench:before,
.ss-wrench.right:after{content:'🔧'}

.ss-nut:before,
.ss-nut.right:after{content:'🔩'}

.ss-screw:before,
.ss-screw.right:after{content:''}

.ss-clock:before,
.ss-clock.right:after{content:'⏲'}

.ss-stopwatch:before,
.ss-stopwatch.right:after{content:'⏱'}

.ss-alarmclock:before,
.ss-alarmclock.right:after{content:'⏰'}

.ss-counterclockwise:before,
.ss-counterclockwise.right:after{content:'⥀'}

.ss-calendar:before,
.ss-calendar.right:after{content:'📅'}

.ss-food:before,
.ss-food.right:after{content:''}

.ss-egg:before,
.ss-egg.right:after{content:''}

.ss-chickenleg:before,
.ss-chickenleg.right:after{content:'🍗'}

.ss-pizza:before,
.ss-pizza.right:after{content:'🍕'}

.ss-birthdaycake:before,
.ss-birthdaycake.right:after{content:'🎂'}

.ss-icecream:before,
.ss-icecream.right:after{content:'🍨'}

.ss-popsicle:before,
.ss-popsicle.right:after{content:''}

.ss-coffee:before,
.ss-coffee.right:after{content:''}

.ss-mug:before,
.ss-mug.right:after{content:'☕'}

.ss-beer:before,
.ss-beer.right:after{content:'🍺'}

.ss-bottle:before,
.ss-bottle.right:after{content:''}

.ss-wineglass:before,
.ss-wineglass.right:after{content:'🍷'}

.ss-wine:before,
.ss-wine.right:after{content:''}

.ss-cocktail:before,
.ss-cocktail.right:after{content:'🍸'}

.ss-cup:before,
.ss-cup.right:after{content:''}

.ss-waterbottle:before,
.ss-waterbottle.right:after{content:''}

.ss-utensils:before,
.ss-utensils.right:after{content:'🍴'}

.ss-measuringcup:before,
.ss-measuringcup.right:after{content:''}

.ss-helmet:before,
.ss-helmet.right:after{content:''}

.ss-graduationcap:before,
.ss-graduationcap.right:after{content:'🎓'}

.ss-glasses:before,
.ss-glasses.right:after{content:'👓'}

.ss-sunglasses:before,
.ss-sunglasses.right:after{content:'🕶'}

.ss-tie:before,
.ss-tie.right:after{content:''}

.ss-bowtie:before,
.ss-bowtie.right:after{content:''}

.ss-tshirt:before,
.ss-tshirt.right:after{content:'👕'}

.ss-jersey:before,
.ss-jersey.right:after{content:''}

.ss-sock:before,
.ss-sock.right:after{content:''}

.ss-handbag:before,
.ss-handbag.right:after{content:'👜'}

.ss-briefcase:before,
.ss-briefcase.right:after{content:'💼'}

.ss-messengerbag:before,
.ss-messengerbag.right:after{content:''}

.ss-hanger:before,
.ss-hanger.right:after{content:''}

.ss-safetypin:before,
.ss-safetypin.right:after{content:''}

.ss-thermometer:before,
.ss-thermometer.right:after{content:''}

.ss-cloud:before,
.ss-cloud.right:after{content:'☁'}

.ss-sun:before,
.ss-sun.right:after{content:'☀'}

.ss-partlycloudy:before,
.ss-partlycloudy.right:after{content:'⛅'}

.ss-rain:before,
.ss-rain.right:after{content:'🌧'}

.ss-thunderstorm:before,
.ss-thunderstorm.right:after{content:'⛈'}

.ss-snow:before,
.ss-snow.right:after{content:'🌨'}

.ss-umbrella:before,
.ss-umbrella.right:after{content:'☂'}

.ss-crescentmoon:before,
.ss-crescentmoon.right:after{content:'🌙'}

.ss-planet:before,
.ss-planet.right:after{content:''}

.ss-recycle:before,
.ss-recycle.right:after{content:'♲'}

.ss-outlet:before,
.ss-outlet.right:after{content:''}

.ss-car:before,
.ss-car.right:after{content:'🚘'}

.ss-train:before,
.ss-train.right:after{content:'🚆'}

.ss-bus:before,
.ss-bus.right:after{content:'🚍'}

.ss-trailer:before,
.ss-trailer.right:after{content:''}

.ss-plane:before,
.ss-plane.right:after{content:'✈'}

.ss-boat:before,
.ss-boat.right:after{content:'🚢'}

.ss-sailboat:before,
.ss-sailboat.right:after{content:'⛵'}

.ss-boat:before,
.ss-boat.right:after{content:'🚢'}

.ss-schooner:before,
.ss-schooner.right:after{content:''}

.ss-rocket:before,
.ss-rocket.right:after{content:'🚀'}

.ss-satellite:before,
.ss-satellite.right:after{content:'🛰'}

.ss-ufo:before,
.ss-ufo.right:after{content:''}

.ss-helm:before,
.ss-helm.right:after{content:'⎈'}

.ss-fuel:before,
.ss-fuel.right:after{content:'⛽'}

.ss-firehydrant:before,
.ss-firehydrant.right:after{content:''}

.ss-theatre:before,
.ss-theatre.right:after{content:'🎭'}

.ss-ticket:before,
.ss-ticket.right:after{content:'🎫'}

.ss-golf:before,
.ss-golf.right:after{content:'⛳'}

.ss-golfshot:before,
.ss-golfshot.right:after{content:''}

.ss-basketball:before,
.ss-basketball.right:after{content:'🏀'}

.ss-hockeymask:before,
.ss-hockeymask.right:after{content:''}

.ss-billiards:before,
.ss-billiards.right:after{content:'🎱'}

.ss-baseball:before,
.ss-baseball.right:after{content:'⚾'}

.ss-baseballfield:before,
.ss-baseballfield.right:after{content:''}

.ss-tennis:before,
.ss-tennis.right:after{content:'🎾'}

.ss-tabletennis:before,
.ss-tabletennis.right:after{content:''}

.ss-boxing:before,
.ss-boxing.right:after{content:''}

.ss-bowling:before,
.ss-bowling.right:after{content:''}

.ss-football:before,
.ss-football.right:after{content:'🏈'}

.ss-soccer:before,
.ss-soccer.right:after{content:'⚽'}

.ss-dumbbell:before,
.ss-dumbbell.right:after{content:''}

.ss-fishhook:before,
.ss-fishhook.right:after{content:''}

.ss-gameplan:before,
.ss-gameplan.right:after{content:''}

.ss-hiker:before,
.ss-hiker.right:after{content:''}

.ss-runner:before,
.ss-runner.right:after{content:'🏃'}

.ss-pedestrian:before,
.ss-pedestrian.right:after{content:'🚶'}

.ss-callbell:before,
.ss-callbell.right:after{content:'🛎'}

.ss-shower:before,
.ss-shower.right:after{content:'🚿'}

.ss-bandage:before,
.ss-bandage.right:after{content:''}

.ss-pill:before,
.ss-pill.right:after{content:'💊'}

.ss-flask:before,
.ss-flask.right:after{content:''}

.ss-testtube:before,
.ss-testtube.right:after{content:''}

.ss-pulse:before,
.ss-pulse.right:after{content:''}

.ss-up:before,
.ss-up.right:after{content:'⬆'}

.ss-right:before,
.ss-right.right:after{content:'➡'}

.ss-down:before,
.ss-down.right:after{content:'⬇'}

.ss-left:before,
.ss-left.right:after{content:'⬅'}

.ss-rightward:before,
.ss-rightward.right:after{content:''}

.ss-leftward:before,
.ss-leftward.right:after{content:''}

.ss-squiggle:before,
.ss-squiggle.right:after{content:'⇝'}

.ss-retweet:before,
.ss-retweet.right:after{content:''}
