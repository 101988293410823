#{$theme-scope} {

  // INPUT GROUP (horizontal button bar example)

  // <span class="sn-input-group">
  //   <span>
  //     <button type="button" class="sn-button small" title="Filter">Button One</button>
  //   </span>
  //   <span>
  //     <button type="button" class="sn-button small" title="Filter">Button Two</button>
  //   </span>
  //   <span>
  //     <button type="button" class="sn-button small" title="Filter">Button Three</button>
  //   </span>
  // </span>

  .sn-input-group {
    @include inline-flex-box(row);
    max-width: 100%;

    > * {
      @include flex(1,1,auto);
      white-space: nowrap;
      margin: 0;

      &:not(:first-child) {
        margin-left: -1px;
        > .sn-button, > input {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      &:not(:last-child) {
        > .sn-button, > input {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }

    // force text field to max width
    > label,
    > label input { width: 100%; }

    .sn-button-container {
      flex-shrink: 0; // fix layout issue in Safari
    }
  }

  .sn-input-group + .sn-input-group {
    margin-left: 4px;
  }

  .sn-input-group-spacing { padding: 0 $sn-spacing-condensed 0 0; }

}
