@charset "UTF-8";

// Sport Ngin fixes to make the spacing behave more like FontAwesome

$glyphish-font-path: "../../assets/latest/fonts";

body [class^="ss-"],
body [class*=" ss-"] {
  display: inline-table;
  vertical-align: middle;

  // fix the left/right clip issue
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  margin-left: -1px;
  margin-right: -1px;

  &:before {
    display: table-cell;
    vertical-align: middle;
    transform: translateY(0.125em);
  }
}

////////////////////////////////////////////////////////////////////




/*
* Symbolset
* www.symbolset.com
* Copyright © 2014 Oak Studios LLC
*
* Upload this file to your web server
* and place this within your <head> tags.
* <link href="webfonts/ss-glyphish-outlined.css" rel="stylesheet" />
*/

@font-face {
  font-family: "SSGlyphish";
  src: url('#{$glyphish-font-path}/ss-glyphish-outlined.eot');
  src: url('#{$glyphish-font-path}/ss-glyphish-outlined.eot?#iefix') format('embedded-opentype'),
       url('#{$glyphish-font-path}/ss-glyphish-outlined.woff') format('woff'),
       url('#{$glyphish-font-path}/ss-glyphish-outlined.ttf')  format('truetype'),
       url('#{$glyphish-font-path}/ss-glyphish-outlined.svg#SSGlyphishOutlined') format('svg');
  font-weight: 400;
  font-style: normal;
}

/* This triggers a redraw in IE to Fix IE8's :before content rendering. */
html:hover [class^="ss-"]{-ms-zoom: 1;}

.ss-icon, .ss-icon.ss-glyphish-outlined,
[class^="ss-"]:before, [class*=" ss-"]:before,
[class^="ss-"].ss-glyphish-outlined:before, [class*=" ss-"].ss-glyphish-outlined:before,
[class^="ss-"].right:after, [class*=" ss-"].right:after,
[class^="ss-"].ss-glyphish-outined.right:after, [class*=" ss-"].ss-glyphish-outlined.right:after {
  font-family: "SSGlyphish";
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  /*-webkit-font-feature-settings: "liga"; Currently broken in Chrome >= v22. Falls back to text-rendering. Safari is unaffected. */
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="ss-"].right:before,
[class*=" ss-"].right:before{display:none;content:'';}
