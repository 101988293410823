#{$theme-scope} {

  .sn-sticky-active {
    position: relative;
    z-index: $sn-z-inline;
    box-shadow: 0 4px 8px -4px rgba(0,0,0,0.5);
    background: #fff;
  }

  .sn-sticky-header, .sn-sticky-column {
    position: fixed;
    table-layout: fixed;
    z-index: $sn-z-inline;
    background: #fff;

    td, th {
      max-width: none !important;
      min-width: auto !important;
    }
  }

  .sn-sticky-header.sn-sticky-column { // overlap
    z-index: $sn-z-inline + 1;
  }

  .sn-table {
    &.sn-sticky-column > * > * > *:last-child {
      padding-right: $sn-spacing-condensed; // override last-child padding from tables
    }

    &.sn-sticky-header [sticky-column] {
      border-right: 1px solid $sn-base-color-54; // darker than header background-color
    }
    [sticky-column] {
      border-right: 1px solid $sn-base-color-72;
    }
  }

  .sn-pane[sticky-container] {
    overflow-x: auto;
  }

}
