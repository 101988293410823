#{$theme-scope} {
  .sn-button:not(.minimal) {
    // <button class="sn-button facebook sn-x100">
    //   <i class="fa fa-facebook-square"></i> Facebook
    // </button>
    &.facebook:not(.disabled):not(:disabled) {
      $fb-light: #4964a0;
      $fb-dark: #365391;

      color: #fff;
      @include background(linear-gradient($fb-light, $fb-dark));
      border-color: darken($fb-dark, 5%);

      &:hover,
      &:active {
        @include background(linear-gradient($fb-dark, $fb-light));
        border-color: darken($fb-dark, 5%);
      }
    }

    // <button class="sn-button google-plus sn-x100">
    //   <i class="fa fa-google-plus-square"></i> Google+
    // </button>
    &.google-plus:not(.disabled):not(:disabled) {
      $gplus-light: #fff;
      $gplus-dark: $sn-base-color-97;

      @include background(linear-gradient($gplus-light, $gplus-dark));

      &:hover,
      &:active {
        @include background(linear-gradient($gplus-dark, $gplus-light));
      }
    }

    &.facebook:not(.disabled):not(:disabled),
    &.google-plus:not(.disabled):not(:disabled) {
      .sn-spinner:before { color: #fff; }

      > .icon {
        left: 6px;
        position: absolute;
        top: 4px;
      }

      > i.fa.icon {
        font-size: 20px;
      }

      > img.icon {
        height: 20px;
        left: 5px;
        width: 20px;
      }
    }
  }
}
