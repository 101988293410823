#{$theme-scope} {

  .sn-button {
    cursor: pointer;
    color: $sn-base-color;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    font-size: $sn-font-size-md;
    font-weight: $sn-font-weight-normal;
    text-decoration: none;
    white-space: nowrap;
    text-align: center;
    text-overflow: ellipsis;
    vertical-align: inherit;
    outline: none;

    svg.icon,
    svg.icon-left,
    svg.icon-right {
      display: inline-block;
      fill: currentColor;
      height: 1em;
      transform: scale(1.4) translateY(12%);
      width: 1em;

      &.small {
        transform: scale(0.8);
      }
    }

    svg.icon-left { margin-right: .2em; }
    svg.icon-left.small { margin-right: 0; }
    svg.icon-right { margin-left: .2em; }
    svg.icon-right.small { margin-left: 0; }

    .sn-spinner {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -0.5em !important;
    }

    &.spinning {
      &, * { color: transparent; }
      .sn-spinner {
        display: block;

        &:before {
          width: calc(1em + 2.6px); // fixes a slight wobble. no idea why.
        }
      }
    }

    &:not(.minimal) {
      @include sn-clickable;
      padding: 0 12px;
      margin: 0 4px;
      height: $sn-input-lg;
      min-width: $sn-input-lg;
      line-height: $sn-input-lg - 2;
      border-radius: 4px;

      &:hover { @include sn-clickable-hover; }
      &:focus { @include sn-clickable-focus; }
      &:active { @include sn-clickable-active; }

      &:disabled,
      &.disabled { @include sn-clickable-disabled; }

      &.inverse {
        background: $sn-base-color;
        color: #fff;
        outline: none;
      }

      &.primary:not(.disabled):not(:disabled) {
        color: #fff;
        @include background(linear-gradient($sn-success-color-10, $sn-success-color));
        border-color: $sn-success-color--22;

        &:hover,
        &:active {
          @include background(linear-gradient($sn-success-color, $sn-success-color-10));
          border-color: $sn-success-color--22;
        }

        .sn-spinner:before { color: #fff; }
      }
    }

    &.small {
      margin: 0 1px;
      font-size: $sn-font-size-sm;
      padding: 0 8px;
      height: $sn-input-md;
      min-width: $sn-input-md;
      line-height: $sn-input-md - 2;
    }

    &.minimal {
      padding: 0;
      margin: 0 8px;
      height: $sn-input-md;
      line-height: $sn-input-md;
      border: none;
      background: none;

      &:hover { color: $sn-base-color--22; }
      &:active { color: $sn-base-color--66; }

      &:disabled, &.disabled {
        cursor: default;
        color: $sn-base-color-72;
      }
    }

    &:first-child { margin-left: 0; }
    &:last-child { margin-right: 0; }

    &.sn-popover-open {
      &.minimal { color: $sn-base-color--66; fill: $sn-base-color--66; }
      &:not(.minimal) { @include sn-clickable-active; }
    }

    &.sn-right {
      margin-bottom: -.4em;
      margin-top: -.2em;

      svg.icon {
        margin-right: .2em;
      }
    }
  }

  .sn-dropdown-button {
    @extend .sn-button;

    &:after {
      margin-left: 4px;
      @include fa-icon($fa-var-angle-down);
    }

    &.small:after {
      margin-left: 3px;
      @include fa-icon($fa-var-angle-down, 12px);
    }
  }

  // buttons in a table
  .sn-table {
    .sn-button.minimal,
    .sn-dropdown-button.minimal {
      height: auto;
    }
  }

  .sn-close-button {
    height: 1.5em;
    width: 1.5em;
    line-height: 1.5em;
    margin: -0.5em;
    border: none;
    background: none;
    color: $sn-base-color;
    font-size: 16px;
    position: absolute;
    top: $sn-spacing;
    right: $sn-spacing;
    opacity: 0.5;
    outline: none;

    svg.icon {
      width: 100%;
      height: 100%;
    }

    &:empty:before { content: '✕'; } // legacy "x"

    &:hover {
      opacity: 1;
      cursor: pointer;
      text-decoration: none;
    }
  }
}
