#{$theme-scope} {

  // <div class="sn-avatar">
  //   <img src="profilepic.png" />
  // </div>

  // Avatar edit styles
  //
  // <div class="sn-avatar-large">
  //   <img src="profilepic.png" />
  //   <div class="edit-actions">
  //     <button class="sn-button minimal"><i class="fa fa-search-plus"></i></button>
  //     <button class="sn-button minimal"><i class="fa fa-camera"></i></button>
  //   </div>
  // </div>

  .sn-avatar {
    position: relative;
    z-index: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;

    &:before {
      opacity: .25;
      background: url('#{$shared-image-path}/avatar.svg') no-repeat;
      background-size: 100%;
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
    }

    button.minimal { text-shadow: 0 1px 2px rgba(0,0,0,0.6); }

    img {
      position: relative;
      width: 100%;
      height: auto;
    }

    .edit-actions {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: rgba(darken($sn-base-color--66, 10), 0.4);

      .sn-button {
        color: #fff;
        display: block;
        margin: 0 auto;
        opacity: 0.5;
        font-size: 8px;
        height: 10px;

        &:hover {
          color: #fff;
          opacity: 1;
        }
      }
    }
  }

  .disabled .sn-avatar {
    opacity: 0.25;
    &:before { opacity: 1; }
  }

  .sn-avatar-medium {
    @extend .sn-avatar;
    width: 75px;
    height: 75px;

    .edit-actions .sn-button {
      height: 20px;
      font-size: 14px;
      margin: -2px auto 2px;
    }
  }

  .sn-avatar-large {
    @extend .sn-avatar;
    width: 120px;
    height: 120px;

    .edit-actions .sn-button {
      height: 30px;
      font-size: 18px;
    }
  }

}
