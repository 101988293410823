// NOTE: This requires the tool tip styles.

// EXAMPLE: Use only the parts you need.

// <span class="sn-slider--multi-point">
//   <span class="sn-slider__range">
//     <a><span>3</span></a>
//     <a><span>12</span></a>
//   </span>
// </span>

.sn-slider,
%sn-slider {
  cursor: default;
  @include no-select;
  font-size: $sn-font-size-xs;
  white-space: nowrap;
  display: block;
  min-width: 80px;
  background: $sn-base-color-90;
  border: 1px solid $sn-base-color-54;
  height: 8px;
  border-radius: 3px;
  padding: 0 2px 0 18px;
}

.sn-slider__range {
  display: block;
  height: 8px;
  margin: -1px 0;
  position: relative;

  a {
    display: block;
    position: absolute;
    text-align: center;
    text-decoration: none;
    right: 100%;
    top: -4px;
    color: $sn-base-color-54;
    background: $sn-base-color-90;
    border: 1px solid $sn-base-color-54;
    border-radius: 2px;
    width: 16px;
    height: 16px;

    &:before {
      @include fa-icon('\f0c9', 8px);
      @include rotate(90deg);
      margin-left: 1px;
      margin-top: 0;
      display: block;
      line-height: 14px;
      height: 14px;
      width: 14px;
      text-rendering: geometricPrecision;
    }

    + a {
      right: auto;
      left: 100%;
    }

    span {
      @extend .sn-tool-tip;
      @extend .sn-tool-tip.above;
      @extend .sn-tool-tip.small;
      position: relative !important;
      display: none;
      top: -44px;
      margin: 0 -200px -5px;
      width: auto;
    }

    &:hover,
    &:active { border-color: $sn-base-color-18; }

    &:active {
      color: $sn-base-color-18;

      span { display: inline-block; }
    }
  }
}

.sn-slider--multi-point {
  @extend %sn-slider;
  padding-right: 18px;
  .sn-slider__range { background: $sn-accent-color; }
}
