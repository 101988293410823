#{$theme-scope} {
  .sn-date-picker {
    $cell-size: 20px;
    $table-horiz-padding: 20px;
    $table-border-spacing: 2px;

    background: #fff;
    border: 1px solid $sn-accent-color;
    text-align: center;
    border-radius: 2px;
    margin: 1px 0;

    table {
      position: relative;
      font-size: $sn-font-size-sm;
      padding: 0 $table-horiz-padding;
      border-spacing: $table-border-spacing;
      table-layout: fixed;
      z-index: 0;

      th { font-weight: $sn-font-weight-normal; }

      .sn-date-picker-nav {
        font-size: $sn-font-size-md;
        height: 30px;
        cursor: pointer;
      }

      .sn-date-picker-weekdays {
        height: 24px;
        color: #fff;

        th:first-child {
          position: relative;

          &:before {
            content: "";
            height: 100%;
            width: calc(7 * 100% + (7 - 1) * #{$table-border-spacing} + 2 * #{$table-horiz-padding + $table-border-spacing});
            left: -($table-horiz-padding + $table-border-spacing);
            position: absolute;
            background-color: $sn-base-color-36;
            top: 0;
            z-index: -1;
          }
        }
      }

      .sn-date-picker-days td {
        height: $cell-size;
        width: $cell-size;
        line-height: $cell-size - 2px;
        color: $sn-base-color--66;
        cursor: pointer;
        border-radius: 2px;
        border: 1px solid transparent;

        &:not(.inactive) {
          &:hover { border: 1px solid $sn-accent-color; }
        }

        &.selected {
          color: #fff;
          background: $sn-accent-color;
          border: 1px solid $sn-accent-color;
        }

        &.inactive {
          color: $sn-base-color-72;
          cursor: default;
        }
      }

      tfoot {
        color: $sn-base-color-54;

        tr { height: 30px; }
      }
    }
  }

  sn-datepicker-calendar.tether-element-attached-top.tether-out-of-bounds-top,
  sn-datepicker-calendar.tether-element-attached-bottom.tether-out-of-bounds-bottom { display: none; }
}
