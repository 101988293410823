#{$theme-scope} {

  .ui-timepicker-wrapper {
    @include sn-dropdown-menu;
    min-width: initial;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100px;
    margin: 1px 0;
    outline: none;
    z-index: $sn-z-modal + $sn-z-dropdown + 1;
  }

  .ui-timepicker-duration {
    margin-left: 8px;
    color: $sn-base-color-54;
  }

  .ui-timepicker-list {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      @include sn-dropdown-menu-item;
      white-space: nowrap;

      &.ui-timepicker-disabled {
        @include sn-dropdown-menu-item-disabled;
      }
    }

    &:not(:hover) li.ui-timepicker-selected,
    li:hover {
      @include sn-dropdown-menu-item-highlighted;

      .ui-timepicker-duration { color: $sn-base-color-36; }
    }
  }

}
