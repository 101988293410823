#{$theme-scope} {

  // <input type="checkbox" id="checkbox" class="sn-checkbox" />
  // <label for="checkbox" class="sn-checkbox">Label Text</label>

  $checkbox-spacing-vert: ($sn-input-md - $sn-font-size-sm * $sn-line-height) / 2;
  $checkbox-spacing-horiz: 4px;
  $checkbox-size: 12px;

  // (24 - 12) * 1.3 / 2

  input.sn-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin-top: $checkbox-spacing-vert + 2px; // moves the actual checkbox behind the icon. line-height of label is 15.6px, 2px ends up above the 12px icon.

    & + label.sn-checkbox {
      @include no-select;
      // We need to get these synced up across
      // components and put in _variables.scss
      display: block;
      cursor: pointer;
      vertical-align: middle;
      font-size: $sn-font-size-sm;
      margin-top: $checkbox-spacing-vert;
      margin-bottom: $checkbox-spacing-vert;
      padding-left: $checkbox-size + $checkbox-spacing-horiz;

      &.inline {
        display: inline-block;
        margin-right: $sn-spacing-extra-condensed - 3;
      }

      &:before {
        @include fa-icon(' ', 10px);
        text-align: center;
        vertical-align: middle;
        display: inline-block;
        margin: -2px $checkbox-spacing-horiz 0 (0 - $checkbox-size - $checkbox-spacing-horiz);
        width: $checkbox-size;
        height: $checkbox-size;
        background: #fff;
        border: 1px solid $sn-base-color-54;
        border-radius: 2px;
      }

      &:hover:before {
        border-color: $sn-base-color-18;
      }

      &:active:before {
        color: $sn-accent-color;
        border-color: $sn-accent-color;
      }

      &:empty:before { margin-right: 0; }
    }

    &:checked + label.sn-checkbox:before {
      content: $fa-var-check;
      color: $sn-accent-color;
    }

    &:indeterminate + label.sn-checkbox:before {
      content: $fa-var-minus;
      color: $sn-base-color-18;
    }

    &:focus + label.sn-checkbox:before {
      color: $sn-accent-color;
      border-color: $sn-accent-color;
    }

    &:disabled + label.sn-checkbox:before {
      cursor: default;
      color: $sn-base-color-54;
      border-color: $sn-base-color-72;
    }
  }

  // remove margin adjustment for inline checkboxes inside tables
  // since they are alligned with vertical align on the cell and
  // it causes extra spacing if there are no other inputs.
  .sn-table {
    tr > * > label.sn-checkbox.inline {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  // Make checkbox white when it's parent
  // is selected (has blue background)
  .selected {
    input.sn-checkbox {

      & + label.sn-checkbox {
        &:before {
          background: transparent;
          color: #fff;
          border: 1px solid #fff;
          opacity: 0.5;
        }

        &:hover:before { opacity: 1; }
      }

      &:checked + label.sn-checkbox:before { opacity: 1; }
    }
  }

}
