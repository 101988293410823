#{$theme-scope} {
  
  .sn-logo-dark {
    display: inline-block;

    &:before {
      content: "";
      display: block;
      padding-top: 17.6%;
      background: url('#{$shared-image-path}/sport-ngin-logo-dark.svg') center center no-repeat;
      background-size: contain;
    }
  }

}
