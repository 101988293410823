// Icon classes for more generic use

$sn-icon-size: 16px;

#{$theme-scope} {

  .sn-icon-inline {
    width: $sn-icon-size;
    height: $sn-icon-size;
    vertical-align: -3px; // this looks the most aligned with inline text at 12px-18px. looks too low on larger texts
    color: $sn-base-color--66;
    fill: $sn-base-color--66;

    &.light {
      color: $sn-base-color;
      fill: $sn-base-color;
    }
  }

  // Shift individual icons to align based on their visual content

  .svg-power-pay { transform: scale(1.25) translateX(-22%); }

}
