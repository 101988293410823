#{$theme-scope} {

  .sn-timezone-field {
    .sn-button.minimal {
      margin: 0;
    }
  }

  .sn-dropdown-menu.sn-timezone-popover {
    width: 265px;
    max-height: 150px;
    overflow: auto;

    > li:hover { background: inherit; }
  }

  .sn-timezone-group {
    margin: 0 -8px 1em -8px;
  }

  .sn-timezone-option {
    cursor: pointer;
    padding: 4px 8px;
  }

  .sn-timezone-option:hover,
  .sn-timezone-option.active {
    @include sn-dropdown-menu-item-highlighted;
  }

  .sn-timezone-group-label {
    @include sn-dropdown-group;
  }

}
