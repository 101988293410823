// <div class="sn-notification warning">
//   <p>Keith Ballard and 4 other players will be removed from the Minnesota Wild roster</p>
//   <p class="sn-help-text">This will not delete their accounts nor permanently delete their
//      history with this team. These players may be added back to the roster</p>
// </div>

#{$theme-scope} {

  $icon-size: 18px;

  .sn-notification {
    position: relative;
    padding-left: $icon-size + $sn-spacing-extra-condensed;

    > *:not(:first-child) { margin-top: $sn-font-size-md; }

    &:before {
      @include fa-icon($fa-var-info-circle, $icon-size);
      color: $sn-accent-color--22;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
    }

    &.success:before {
      content: $fa-var-check-circle;
      color: $sn-success-color;
    }

    &.warning:before {
      content: $fa-var-exclamation-circle;
      color: $sn-warning-color;
    }

    &.error:before {
      content: $fa-var-ban;
      color: $sn-error-color;
    }

    .add-padding {
      margin-bottom: 14px;
      padding-left: $icon-size + $sn-spacing-extra-condensed;
    }
  }

}
