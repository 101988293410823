#{$theme-scope} {

  // <div class="sn-alert error">
  //   <button class="sn-close-button"></button>
  //   <p>This is the alert message.</p>
  //   <p class="sn-alert-description">Here is a longer description of the alert message.</p>
  //   <div class="sn-alert-actions">
  //     <span><a href="/some/action">Action 1</a></span>
  //     <span><a href="/another/action">Action 2</a></span>
  //   </div>
  // </div>

  $alert-offset: 8px;
  $alert-spacing: 12px;
  $alert-close-spacing: 36px;

  // Floating alerts container

  .sn-alerts-container {
    position: absolute;
    top: $alert-offset;
    right: $alert-offset;

    .sn-alert {
      box-shadow: 0 1px 4px rgba(0,0,0,0.15);
      float: right;
      clear: right;
      z-index: $sn-z-alert;
      margin: $alert-spacing/2;
      max-width: 30em;
    }

  }

  // Alert visual style

  .sn-alert {
    font-weight: $sn-font-weight-normal;
    padding: $alert-spacing - 2 $alert-spacing;
    padding-right: $alert-close-spacing;
    position: relative;
    border-style: solid;
    border-width: 1px;
    border-radius: 2px;

    .sn-close-button {
      top: $alert-spacing;
      right: $alert-spacing;
      color: inherit;
    }

    &.success {
      border-color: $sn-success-color;
      color: $sn-success-color;
      background-color: $sn-success-color-95;
    }

    &.warning {
      border-color: $sn-warning-color;
      color: $sn-warning-color;
      background-color: $sn-warning-color-95;
    }

    &.error {
      border-color: $sn-error-color;
      color: $sn-error-color;
      background-color: $sn-error-color-95;
    }
  }

  .sn-alert-description {
    font-weight: $sn-font-weight-normal;
    font-size: $sn-font-size-sm;
    margin: 0.5em 0;
  }

  .sn-alert-actions {
    font-size: $sn-font-size-sm;
    text-align: right;
    margin-top: $alert-spacing;
    margin-right: $alert-spacing - $alert-close-spacing;

    a {
      color: inherit;
      text-decoration: underline;
    }

    > * {
      display: inline-block;
      white-space: nowrap;
      margin-left: $alert-spacing;

      &:first-child { margin-left: 0; }
    }
  }

}
