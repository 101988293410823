/*

  NOTE: This is only for form structure styles, not elements
        styles. Individual elements should get their own files.

*/

#{$theme-scope} {

  $label-height: $sn-font-size-sm * $sn-line-height;
  $field-spacing: 4px;

  .sn-field {
    font-size: $sn-font-size-sm;
    position: relative;
    display: block;

    &.top-label {
      padding-top: $label-height + $field-spacing;

      > .sn-label:first-child,
      > .field_with_errors:first-child > .sn-label {
        margin-top: 0 - $label-height - $field-spacing;
        margin-bottom: $field-spacing;
        min-height: $label-height;
        display: block;
      }
    }
  }

  .sn-label {
    color: $sn-base-color;
    display: inline-block;
    vertical-align: baseline;

    &.required:after {
      content: " * ";
      font-weight: $sn-font-weight-bold;
      color: $sn-error-color;
    }

    &.disabled {
      color: $sn-base-color-72
    }
  }

  .sn-help-text {
    display: block;
    margin-top: $field-spacing;
    font-size: $sn-font-size-sm;
    color: $sn-base-color-18;

    &.collapse-y {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
