#{$theme-scope} {

  // EXAMPLE INPUT

  // <div class="sn-field">
  //   <label class="sn-text-field with-icon">
  //     <input type="text" placeholder="Search" />
  //     <i class="icon fa fa-search"></i>
  //   </label>
  // </div>

  // OR, Rails-style forms (example with errors):

  // <div class="sn-field top-label">
  //   <div class="field_with_errors">
  //     <label class="sn-label" for="user_email_on_create">Email</label>
  //   </div>
  //   <div class="field_with_errors">
  //     <input class="sn-text-field sn-x100" type="email" name="user[email_on_create]" id="user_email_on_create">
  //   </div>
  // </div>

  $icon-size: 12px;

  .sn-text-field {
    display: inline-block;
    vertical-align: middle;
    position: relative;

    > .icon {
      display: none; // requires .sn-text-field.with-icon class for correct spacing
      cursor: pointer;
      color: $sn-base-color;
      position: absolute;
      z-index: $sn-z-inline;
      right: 8px;
      top: 0;
      height: $sn-input-md;
      font-size: $icon-size;

      &:before {
        display: table-cell;
        vertical-align: middle;
      }
    }

    &:hover > input {
      @include sn-typeable-hover;
    }

    &.with-icon {
      > input { padding-right: $icon-size*2; }
      > .icon { display: table; }
    }
  }

  input.sn-text-field, .sn-text-field > input {
    @include sn-typeable;
    @include placeholder($sn-base-color-54);

    &:focus {
      ~ .icon { color: $sn-accent-color; }
    }

    &:disabled {
      ~ .icon:before { color: $sn-base-color-72; }
    }
  }

  // override font-size of currency fields (which are renderd with pl markup) for backwards compatibility
  .sn-currency-field > * {
    font-size: $sn-font-size-sm !important;
  }

  // validation styles when inside sn-field

  .sn-field {

    &.error,
    &.warning,
    &.success {
      .sn-text-field {
        > input { padding-right: $icon-size*2; }
        > .icon { display: none; }

        &:after {
          display: block;
          @include fa-icon('', $icon-size);
          position: absolute;
          z-index: $sn-z-inline;
          right: 8px;
          top: 50%;
          margin-top: -1-$icon-size/2;
        }
      }
    }

    &.error .sn-text-field {

      > input { border-color: $sn-error-color; }

      &:hover,
      &:focus {
        > input { border-color: $sn-error-color--22; }
      }

      &:after {
        content: $fa-var-ban;
        color: $sn-error-color;
      }
    }

    &.warning .sn-text-field {

      > input { border-color: $sn-warning-color; }

      &:hover,
      &:focus {
        > input { border-color: $sn-warning-color--22; }
      }

      &:after {
        content: $fa-var-exclamation-circle;
        color: $sn-warning-color;
      }
    }

    &.success .sn-text-field {

      > input { border-color: $sn-success-color; }

      &:hover,
      &:focus {
        > input { border-color: $sn-success-color--22; }
      }

      &:after {
        content: $fa-var-check-circle;
        color: $sn-success-color;
      }
    }

    %field_with_errors-input {

      border-color: $sn-error-color;

      &:hover,
      &:focus {
        border-color: $sn-error-color--22;
      }
    }

    // Very basic Rails-style errors
    > .field_with_errors {

      > input:first-child {
        @extend %field_with_errors-input;
      }

    }
  }

  // match any element to text field text style
  .sn-text-field-text,
  %sn-text-field-text {
    @include sn-typable-placeholder;
  }

  // match any element to text field style and spacing
  .sn-text-field-placeholder {
    @extend %sn-text-field-text;
    padding: 0 9px;
  }



}
