#{$theme-scope} {

  // NOTE: This requires the input group styles.

  // SEARCH (variation with full rounded corners)

  // <span class="sn-input-group sn-search">
  //   <span>
  //     <button type="button" class="sn-button small" title="Filter">
  //       <i class="icon ss-icon ss-filter"></i>
  //     </button>
  //   </span>
  //   <label class="sn-text-field with-icon">
  //     <input placeholder="Search" />
  //     <i class="icon fa fa-search"></i>
  //   </label>
  // </span>

  .sn-search {
    $search-radius: $sn-input-md/2;

    // make sure search fields behave
    input[type=search] {
       -moz-appearance:none;
       -webkit-appearance:none;
    }
    input[type="search"] {
      -webkit-appearance: textfield;
    }

    > *:first-child {
      > .sn-button, > input {
        border-top-left-radius: $search-radius;
        border-bottom-left-radius: $search-radius;
      }

      &.sn-text-field.with-icon {

        > input { padding-left: 16px; }
      }
    }

    > *:last-child {
      > .sn-button, > input {
        border-top-right-radius: $search-radius;
        border-bottom-right-radius: $search-radius;
      }

      &.sn-text-field.with-icon {

        > input { padding-right: 28px; }

        .icon,
        .sn-clear-field { right: 12px; }
      }
    }
  }

}
