#{$theme-scope} {
  // <i class="fa fa-spinner fa-4x fa-spin"></i>
  // <i class="sn-spinner"></i>

  .sn-spinner {
    line-height: 1;

    &:before {
      @include fa-icon($fa-var-spinner);
      color: $sn-base-color;
      display: inline-block;
      width: calc(1em + 2px);
      height: calc(1em + 2px); // + 2px to account for margin: -1 from fa-icon
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      @include animation(sn-spin 800ms infinite steps(8));
    }
  }


  // <section ng-if="loading" class="sn-pane sn-loading-section">
  //   <i class="sn-spinner"></i>
  // </section>

  .sn-loading-section {
    text-align: center;

    .sn-spinner:before {
      font-size: 48px;
      width: calc(1em + 3px); // fixes a slight wobble. no idea why.
    }

    &.center {
      position: relative;
      height: 100%;

      .sn-spinner {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        margin-top: -24px;
      }
    }
  }

}
