#{$theme-scope} {

  .sn-page {
    @include sn-fx-fade;
    color: $sn-base-color--44;
    background-color: #fff;
  }

  .sn-page-header {

    &:not(.collapse-bottom) { border-bottom: 1px solid #D5DADD; }

    > h1, .sn-page-title {
      font-weight: $sn-font-weight-normal;
      font-size: 24px;
      margin: 0;

      > small {
        display: inline-block;
        font-weight: $sn-font-weight-normal;
        font-size: $sn-font-size-sm;
        color: $sn-base-color-18;
        margin-left: $sn-spacing-condensed - ($sn-font-size-xl / 4);
      }
    }

    .sn-tabs:first-child { margin-top: 0; }
  }

  .sn-page-actions {
    .title {
      display: inline-block;
      font-weight: $sn-font-weight-light;
      font-size: $sn-font-size-xjumbo;
      transform: translateY(-10%);

      &.small {
        font-size: $sn-font-size-jumbo;
      }
    }
  }

  // Add spacing to prevent page actions from overlapping
  // with a panel’s close button
  .sn-panel-medium .sn-page-actions .sn-col:last-child {
    margin-right: $sn-spacing * 2;
  }

  .sn-page-content {
    @include sn-fx-fade;
  }

  .sn-page-footer {
    border-top: 1px solid #D5DADD;
  }

  .sn-section-header {
    line-height: 1; // Why do I have to do this? Add defaults somewhere?
    margin: $sn-spacing-condensed 0;

    &:first-child { margin-top: 0; }

    > h2 {
      display: inline-block;
      font-size: $sn-font-size-md;
      font-weight: $sn-font-weight-normal;
    }
  }

  .sn-section-summary {
    .sn-details-table { white-space: nowrap; }
  }

  .sn-divider {
    border: none;
    height: 1px;
    margin: $sn-spacing 0;
    width: 100%;
    background: $sn-base-color-72;

    &.condense-y { margin: $sn-spacing-condensed 0; }
    &.collapse-y { margin: 0; }
  }

}
