#{$theme-scope} {
  .sn-group-icon {
    width: 32px;
    height: 32px;
  }

  .sn-group-icon--large {
    width: 48px;
    height: 48px;
  }

  .sn-group-image {
    background: center no-repeat;
    background-size: cover;
    border-radius: 50%;
  }

  .sn-static-group-icon {
    background: url('#{$shared-image-path}/group.svg') no-repeat center;
  }

  .sn-smart-group-icon {
    background: url('#{$shared-image-path}/smart-group.svg') no-repeat center;
  }

  .sn-roster-icon {
    background: url('#{$shared-image-path}/team-roster-group.svg') no-repeat center;
  }

  .sn-static-group-icon, .sn-smart-group-icon, .sn-roster-icon {
    background-size: 100%;
  }

}
