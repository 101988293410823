@media print {
  @page {
    size: letter portrait;
  }

  * {
    -webkit-print-color-adjust: exact; // print colors on table headers, etc.
  }

  .sn-split-vert,
  .sn-split-horiz,
  .sn-pane-inner-scroller {
    display: block !important;
    height: auto !important;
    position: static !important;
    width: 100% !important;
  }

  .sn-side-nav {
    display: none !important;
  }

  .sn-pane,
  .sn-pane-0,
  .sn-pane-1,
  .sn-pane-2,
  .sn-pane-3,
  .sn-pane-4,
  .sn-pane-5,
  .sn-pane-6,
  .sn-pane-7,
  .sn-pane-8,
  .sn-pane-9,
  .sn-pane-10,
  .sn-pane-11,
  .sn-pane-12 {
    flex: 0 1 auto !important;
    height: auto !important;
    overflow: visible !important;
  }
}
