#{$theme-scope} {

  $error-icon-size: 48px;

  .sn-error-page {
    text-align: center;
    color: $sn-base-color--66;

    .sn-logo-dark { width: 240px; }
  }

  .sn-error-state {
    text-align: center;

    .sn-error-state-icon {
      font-size: $error-icon-size;
      line-height: 1;
      margin-bottom: $sn-spacing;
    }

    &.warning .sn-error-state-icon { color: $sn-warning-color; }
    &.error .sn-error-state-icon { color: $sn-error-color; }

    .sn-error-state-title {
      font-size: 20px;
      font-weight: $sn-font-weight-normal;
      margin: 1em 0 $sn-spacing-extra-condensed;
    }

    .sn-error-state-message {
      color: $sn-base-color;
      margin-bottom: $sn-spacing-condensed;
    }
  }

  .sn-error-footer {
    color: $sn-base-color-36;

    li {
      list-style: none;
      display: inline-block;

      &:not(:first-child) { margin-left: $sn-spacing-condensed; }
    }
  }

}
