$sn-select2-icon-size: 14px; // global for use at the bottom of this file.

// NOTE: `.select2v3` class added for compatability alongside Select2 v4

#{$theme-scope} {

  @mixin sn-select2-icon {
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
  }

  // SINGLE SELECT

  .select2-container.select2v3:not(.select2-container-multi) {
    @include sn-clickable;
    display: inline-block;
    height: $sn-input-md;
    line-height: $sn-input-md - 2;
    margin: 0;
    color: $sn-base-color;
    display: block;
    border-radius: 2px;

    &:focus, *:focus { outline: none !important; }

    &:hover { @include sn-clickable-hover; }

    &.select2-container-disabled {
      border-color: $sn-base-color-72 !important;
      background: #FFF !important;
      cursor: default;

      .select2-choice { color: $sn-base-color-72 !important; }
      .select2-search-choice-close,
      .select2-arrow { display: none !important; }
    }

    &.select2-container-active { @include sn-clickable-focus; }
    &.select2-dropdown-open { background: #FFF; }

    .select2-choice {
      height: 100%;
      cursor: inherit;
      text-decoration: none !important; // select2 uses an a tag
      color: $sn-base-color;
      position: relative;
      display: block;
      vertical-align: middle;
      font-size: $sn-font-size-sm;
      padding: 0 $sn-dropdown-spacing-horiz;

      > .select2-chosen {
        margin-right: $sn-dropdown-spacing-horiz*2 + $sn-select2-icon-size;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        float: none;
        width: auto;
      }
    }

    .select2-search-choice-close {
      @include sn-select2-icon;
      display: none;
      color: $sn-base-color-36;
      right: $sn-dropdown-spacing-horiz*2 + $sn-select2-icon-size*.5;
      top: 0;

      &:before { content: '✕'; }
      &:hover { color: $sn-base-color--22; }
    }

    &.select2-allowclear .select2-search-choice-close { display: block; }

    .select2-arrow {
      @include sn-select2-icon;
      color: $sn-base-color--22;
      right: $sn-dropdown-spacing-horiz;
      line-height: $sn-input-md - 2;

      :before { @include fa-icon($fa-var-angle-down, $sn-select2-icon-size); }
    }
  }

  // DROPDOWN

  .select2-drop-mask {
    border: 0;
    margin: 0;
    padding: 0;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: $sn-z-modal + $sn-z-dropdown - 1;
    /* styles required for IE to work */
    background-color: #fff;
    filter: alpha(opacity=0);
  }

  .select2-drop {
    @include sn-dropdown-menu;
    width: 100%;
    margin: 1px 0;
    position: absolute;
    z-index: $sn-z-modal + $sn-z-dropdown;

    &.select2-drop-above { bottom: 100%; }
    &:not(.select2-drop-above) { top: 100%; }
  }

  .select2-drop-auto-width { width: auto; }

  .select2v3 {

    // SEARCH BOX

    .select2-search {
      display: block;
      width: 100%;
      padding: 0 $sn-dropdown-spacing-vert; // even spacing for this
      position: relative;
    }

    .select2-search input {
      @include sn-typeable-sm;
      width: 100%;
      margin-bottom: $sn-dropdown-spacing-vert;
    }

    .select2-search input.select2-active {
      // spinner background needed... not sure how since this is an input (:after is not allowed)
    }

    .select2-hidden-accessible {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    // SEARCH RESULTS

    .select2-results {
      max-height: 200px;
      position: relative;
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      ul.select2-result-sub {
        margin: 0;
        padding-left: 0;
      }

      li {
        list-style: none;
        display: list-item;
        background-image: none;

        em {
          background: #feffde;
          font-style: normal;
        }
      }

      .select2-highlighted {
        @include sn-dropdown-menu-item-highlighted;
      }

      .select2-disabled { background: none; color: $sn-base-color-72; }

      .select2-selected { display: none; }

      .select2-no-results,
      .select2-searching,
      .select2-ajax-error,
      .select2-selection-limit {
        color: $sn-base-color-54;
        display: list-item;
        padding: $sn-dropdown-spacing-vert $sn-dropdown-spacing-horiz;
      }
    }

    .select2-result-label {
      @include sn-dropdown-menu-item;

      &:hover { @include sn-dropdown-menu-item-highlighted; }
    }

    .select2-results-dept-1 .select2-result-label { padding-left: $sn-dropdown-spacing-horiz * 2; }
    .select2-results-dept-2 .select2-result-label { padding-left: $sn-dropdown-spacing-horiz * 3; }
    .select2-results-dept-3 .select2-result-label { padding-left: $sn-dropdown-spacing-horiz * 4; }
    .select2-results-dept-4 .select2-result-label { padding-left: $sn-dropdown-spacing-horiz * 5; }
    .select2-results-dept-5 .select2-result-label { padding-left: $sn-dropdown-spacing-horiz * 6; }
    .select2-results-dept-6 .select2-result-label { padding-left: $sn-dropdown-spacing-horiz * 7; }
    .select2-results-dept-7 .select2-result-label { padding-left: $sn-dropdown-spacing-horiz * 8; }

    .select2-result-with-children > .select2-result-label { @include sn-dropdown-group; }


    .select2-more-results.select2-active {
      // TODO: show spinner while lazy loading more results
    }

    .select2-results .select2-ajax-error {
      color: $sn-error-color;

      :before { @include fa-icon($fa-var-ban, 18px); }
    }

    .select2-more-results { display: list-item; }

    .select2-match { text-decoration: underline; }

  }

  // MULTI-SELECT

  .select2-container-multi.select2v3 {

    .select2-choices {
      @include sn-tag-input;

      .select2-search-field {
        line-height: $sn-input-md - $sn-tag-spacing * 2;
        padding: 0 $sn-tag-spacing;
        float: left;
        list-style: none;
        margin: 0;
        white-space: nowrap;

        input {
          @include sn-reset-typeable;

          &.select2-active { /* TODO: need spinner here while loading... not sure how since it's an input */ }
          &.select2-default { // placeholder
            color: $sn-base-color-54;
            &:disabled { color: $sn-base-color-72; }
          }
        }
      }
      .select2-search-choice-focus { background: $sn-accent-color; }
    }

    &.select2-container-active .select2-choices { @include sn-clickable-focus; }

    .select2-search-choice {
      @include sn-tag;
      float: left;
      margin: $sn-tag-spacing $sn-tag-spacing 0 0;

      &.select2-chosen { cursor: default; }
      &.select2-locked { @include sn-tag-fixed; }
    }

    .select2-search-choice-close { @include sn-tag-close; }

    &.select2-container-disabled {

      .select2-search-choice {
        @include sn-tag-fixed;
        background-color: $sn-base-color-72;
      }

      .select2-search-choice-close { display: none !important; }
    }
  }

  // UTILITY CLASSES

  .select2-offscreen, .select2-offscreen:focus {
    clip: rect(0 0 0 0) !important;
    width: 1px !important;
    height: 1px !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
    position: absolute !important;
    outline: 0 !important;
    left: 0px !important;
    top: 0px !important;
  }

  .select2-display-none { display: none; }

  .select2-measure-scrollbar {
    position: absolute;
    top: -10000px;
    left: -10000px;
    width: 100px;
    height: 100px;
    overflow: scroll;
  }

}

// RTL MODS

html[dir="rtl"]#{$theme-scope},
html[dir="rtl"] #{$theme-scope} {

  .select2-container.select2v3:not(.select2-container-multi) {

    .select2-choice {
      > .select2-chosen {
        margin-right: 0;
        margin-left: $sn-dropdown-spacing-horiz*2 + $sn-select2-icon-size;
      }
    }

    .select2-arrow {
      right: auto;
      left: $sn-dropdown-spacing-horiz;
    }
    .select2-search-choice-close {
      right: auto;
      left: $sn-dropdown-spacing-horiz*2 + $sn-select2-icon-size*.5;
    }
  }

  .select2-container-multi.select2v3 {

    .select2-choices {
      li { float: right; }
      .select2-search-choice {
        padding-left: $sn-tag-padding-horiz + $sn-tag-icon-size;
        padding-right: $sn-tag-padding-horiz;
      }
    }

    .select2-search-choice-close {
      right: auto;
      left: $sn-tag-padding-horiz / 2;
    }
  }
}
