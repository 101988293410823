// <section class="provisioning">
//   <i class="provisioning-icon ss-icon ss-usergroup"></i>
//   <h1 class="provisioning-title">No Players</h1>
//   <p class="provisioning-message">
//     You can add players manually or import a file full of them.
//     If you're importing, <a href="#">download our template.</a>
//   </p>
//   <button class="sn-button minimal"><i class="fa fa-plus"></i> Add Players</button>
//   <button class="sn-button minimal"><i class="fa fa-upload"></i> Import Player</button>
// </section>

$sn-provisioning__header--font-size: 36px;
$sn-provisioning__header--icon-size: 64px;
$sn-provisioning__step--icon-size: 40px;

%sn-provisioning {
  border-spacing: $sn-spacing-extra-condensed;
  display: block;
  width: 100%;
  text-align: left;

  // temp until we can update the size across the board
  .sn-help-text { font-size: $sn-font-size-md; }
}

.sn-provisioning {
  @extend %sn-provisioning;
  margin-top: $sn-provisioning__header--icon-size + $sn-spacing;
}

.sn-provisioning--small {
  @extend %sn-provisioning;
  margin-top: $sn-spacing;
}

.sn-provisioning--modal {
  @extend %sn-provisioning;
  padding: $sn-spacing;
}


.sn-provisioning__container {
  display: table;
  max-width: 600px;
  margin: 0 auto;
}

.sn-provisioning__icon-container {
  display: table-cell;
  vertical-align: top;
  width: $sn-provisioning__header--icon-size;
  height: $sn-provisioning__header--icon-size;
  padding-bottom: $sn-spacing-condensed;
  padding-right: $sn-spacing-condensed;
}

.sn-provisioning__icon {
  fill: $sn-base-color;
  margin-top: $sn-provisioning__header--font-size * 0.2;
  height: $sn-provisioning__header--icon-size;
  width: $sn-provisioning__header--icon-size;

  // Shift individual icons to align based on their visual content
  &.svg-power-pay { transform: scale(1.3) translateX(-12%); }
  &.svg-bank { transform: scale(1.3); }
}

.sn-provisioning__header {
  display: table-cell;
  vertical-align: top;
  padding-bottom: $sn-spacing-condensed;

  > h1 {
    font-size: $sn-provisioning__header--font-size;
    font-weight: $sn-font-weight-light;
  }

  > h2 {
    font-size: $sn-font-size-md;
    font-weight: $sn-font-weight-light;
    margin-top: $sn-spacing-extra-condensed;
  }
}

.sn-provisioning__steps {
  list-style: none;
  display: table-row-group;
}

.sn-provisioning__step {
  display: table-row;
  text-align: left;
  
  h3 {
    font-weight: $sn-font-weight-bold;
    line-height: $sn-line-height;
  }
}

.sn-provisioning__step-icon {
  fill: $sn-base-color-54;
  display: inline-block;
  vertical-align: middle;
  height: $sn-provisioning__step--icon-size;
  width: $sn-provisioning__step--icon-size;
}

.sn-provisioning__step-icon-container {
  display: table-cell;
  width: $sn-provisioning__header--icon-size;
  text-align: right;
  padding-right: $sn-spacing-condensed;
}

.sn-provisioning__step-text {
  display: table-cell;
  vertical-align: middle;
  &.spaced {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  button {
    margin-top: 6px;
  }
}
