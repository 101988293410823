#{$theme-scope} {

  // EXAMPLE (date field: [ mm / dd / yyyy ])

  // <div class="sn-field">
  //   <label class="sn-masked-field">
  //     <input size="3" placeholder="mm" />
  //     <span>/</span>
  //     <input size="3" placeholder="dd" />
  //     <span>/</span>
  //     <input size="5" placeholder="yyyy" />
  //     <span class="frame"></span>
  //   </label>
  // </div>

  $icon-size: 12px;

  @mixin sn-masked-field-component {
    position: relative;
    z-index: 5;
    display: inline-block;
    vertical-align: top;
    line-height: inherit;
  }

  .sn-masked-field {
    @include sn-typeable-base;
    border: none;
    height: $sn-input-lg;
    line-height: $sn-input-lg;
    z-index: 0;
    color: $sn-base-color-54;
    overflow: hidden;
    white-space: nowrap;

    &[disabled] { color: $sn-base-color-72; }
    &:not([disabled]) { cursor: text; }

    > span {
      @include sn-masked-field-component;
    }

    > input {
      @include sn-reset-typeable;
      @include sn-masked-field-component;
      @include sn-placeholder-text;
      text-align: center;
      color: $sn-base-color--22;

      &:disabled { color: $sn-base-color-72; }
    }

    .frame {
      @include sn-typeable-base;
      position: absolute;
      z-index: 0;
      top: 0px;
      left: 0px;
      height: 100%;
      font-size: 14px;
      width: 100%;
      // fixes overflow issue that clips border
      display: table;
      table-layout: fixed;
    }

    &:hover .frame { @include sn-typeable-hover; }
    > input:focus ~ .frame { @include sn-typeable-focus; }
    > input:disabled ~ .frame { border-color: $sn-base-color-72; }

  }

  // validation styles when inside sn-field

  .sn-field {

    &.error,
    &.warning,
    &.success {
      .sn-masked-field {
        padding-right: $icon-size*2;

        &:after {
          display: block;
          @include fa-icon('', $icon-size);
          position: absolute;
          z-index: $sn-z-inline;
          right: 8px;
          top: 50%;
          margin-top: -1-$icon-size/2;
        }
      }
    }

    &.error .sn-masked-field {

      .frame { border-color: $sn-error-color; }

      &:hover .frame,
      > input:focus ~ .frame {
        border-color: $sn-error-color--22;
      }

      &:after {
        content: $fa-var-ban;
        color: $sn-error-color;
      }
    }

    &.warning .sn-masked-field {

      > .frame { border-color: $sn-warning-color; }

      &:hover .frame,
      > input:focus ~ .frame {
        border-color: $sn-warning-color--22;
      }

      &:after {
        content: $fa-var-exclamation-circle;
        color: $sn-warning-color;
      }
    }

    &.success .sn-masked-field {

      > .frame { border-color: $sn-success-color; }

      &:hover .frame,
      > input:focus ~ .frame {
        border-color: $sn-success-color--22;
      }

      &:after {
        content: $fa-var-check-circle;
        color: $sn-success-color;
      }
    }
  }


}
