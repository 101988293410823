#{$theme-scope} {

  $medium: 120px;

  .sn-rounded-image {
    width: $medium;
    height: 0;
    padding: $medium/2 0;
    border-radius: 50%;
    border: 1px solid $sn-base-color-90;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
  }
}
